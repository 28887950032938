<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h4>
                  {{ `${id ? 'Update' : 'Add'} slider` }}
                </h4>
                <router-link :to="{name:'slider'}"> Sliders</router-link>
                \

                {{ `${id ? 'Update' : 'Add'}` }}
              </div>
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-md-8  col-sm-12 form-group">
                <v-text-field outlined dense label="Title" v-model="slider.title"></v-text-field>
                <span class="text-danger" v-if="$v.slider.title.$error">This information is required</span>
              </div>
              <div class="col-md-4  col-sm-12 form-group">
                <v-select :items="slider_types" outlined dense label="Type" v-model="slider.slider_type_id" item-text="title"
                          item-value="id"></v-select>
<!--                <span class="text-danger" v-if="$v.slider.slider_type_id.$error">Type is required</span>-->
              </div>
              <div class="col-md-12 col-sm-12 form-group">
                <v-text-field outlined dense label="Heading text" v-model="slider.heading_text"></v-text-field>
              </div>
              <div class="col-md-12  col-sm-12 form-group">
                <v-text-field outlined dense label="Sub heading text" v-model="slider.sub_heading_text"></v-text-field>
              </div>
              <div class="col-12 form-group">
                <v-textarea outlined dense label="Description" v-model="slider.description"></v-textarea>
              </div>
              <div class="col-md-12 col-sm-12 form-group">
                <v-file-input outlined dense label="Banner image" accept="image/*" prepend-inner-icon="mdi-attachment"
                              prepend-icon=""
                              @change="checkSize($event)" v-model="slider.image"></v-file-input>
                <span class="text-danger" v-if="$v.slider.image.$error">This information is required</span>
                <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                <v-text-field outlined dense label="Button text" v-model="slider.button_text"></v-text-field>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                <v-text-field outlined dense label="Link" v-model="slider.link"></v-text-field>
              </div>
              <div class="col-md-3 form-group">
                <v-text-field outlined dense label="Position" v-model="slider.position"></v-text-field>
                <!-- <span class="text-danger" v-if="$v.slider.is_active.$error">Title is required</span> -->
              </div>

              <div class="col-md-3 col-sm-12 form-group">
                <v-switch label="Show button" v-model="slider.show_button" ></v-switch>

              </div>
              <div class="col-md-3 col-sm-12 form-group">
                <v-switch label="Open in new tab" v-model="slider.new_tab" ></v-switch>


                <!-- <span class="text-danger" v-if="$v.slider.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-md-3 col-sm-6 form-group">
                <v-switch label="Status" v-model="slider.is_active" ></v-switch>

                <!-- <span class="text-danger" v-if="$v.slider.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-12">
                <img
                    v-if="slider.image_path"
                    :src="slider.image_path['thumb']"
                    alt
                    style="height:75px"
                    class="img-thumbnail"
                />
              </div>

              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn
                      class="btn text-gray btn-standard cancel-btn"
                      depressed
                      @click="redirectTo"
                  >Cancel
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('slider', 'create')"
                      class="text-white btn btn-primary btn px-9 py-4 my-3 font-size-3 mx-4"
                      depressed
                      :disabled="sizeExceed"
                      @click="createOrUpdate"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SliderService from "@/core/services/slider/SliderService";
import {required} from "vuelidate/lib/validators";
import SliderTypeService from "@/core/services/slider/type/SliderTypeService";

const slider = new SliderService();
const sliderTypeService = new SliderTypeService();
export default {
  name: "slider-new",
  validations: {
    slider: {
      title: {required},
      image: {required},
      // slider_type_id: {required}
    }
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      slider_types: [],
      slider: {
        id: null,
        slider_type_id: null,
        title: null,
        heading_text: null,
        sub_heading_text: null,
        button_text: 'Read More',
        show_button: true,
        link: null,
        image: null,
        position: null,
        new_tab: 0,
        description: null,
        type: null,
        is_active: true
      }
    };
  },
  mounted() {
    this.id ? this.getSlider(this.id) : "";
    this.getSliderTypes()
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  methods: {
    getSliderTypes() {
      sliderTypeService.allActive().then(response => {
        this.slider_types = response.data
      })
    },
    getSlider(id) {
      slider.show(id).then(response => {
        this.slider = response.data.slider;
        this.slider.is_active ? (this.slider.is_active = true) : false;
        this.slider.new_tab ? (this.slider.new_tab = true) : false;
        this.edit = true;
      });
    },
    redirectTo() {
      this.$router.push({name: "slider"});
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    saveSlider(fd) {
      this.isBusy = true;
      slider
          .store(fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.$tabs.close().then(response => {
              this.$router.push({name: "slider"});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    createOrUpdate() {
      this.$v.slider.$touch();
      if (this.$v.slider.$error) {
        setTimeout(() => {
          this.$v.slider.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        // console.log(fd);
        if (this.edit) {
          this.updateSlider(fd);
        } else {
          this.saveSlider(fd);
        }
      }
    },
    updateSlider(fd) {
      this.isBusy = true;
      slider
          .update(this.slider.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$tabs.close().then(response => {
              this.$router.push({name: "slider"});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.slider) {
        if (
            key == "image" &&
            this.slider["image"] &&
            this.slider["image"] != null
        ) {
          if (typeof this.slider["image"] == "object") {
            fd.append("image", this.slider[key]);
          }
        } else if (key == "new_tab" && this.slider["new_tab"]) {
          fd.append("new_tab", this.slider.new_tab ? 1 : 0);
        } else if (key == "is_active") {
          fd.append("is_active", this.slider.is_active ? 1 : 0);
        } else {
          // if (key != "id" && this.slider[key]) {
            fd.append(key, this.slider[key]?this.slider[key]:'');
          // }
        }
      }
      return fd;
    }
  }
};
</script>
